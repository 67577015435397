/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';
import { Store } from '@ngrx/store';
import * as fromApp from '../../app.reducer';
import { Token } from '../../providers/auth/auth.types';
import { RestError } from './rest.types';
import { Observable, Subscription, from, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class RestService {
	private _token: Token;
	private _tokenSubscription: Subscription;

	constructor(
		private httpClient: HttpClient,
		private platform: Platform,
		private http: HTTP,
		private store: Store<fromApp.AppState>
	) {
		this._tokenSubscription = this.store.select('auth').subscribe((e: Token) => {
			console.log('rest service token update');
			console.log(e);
			if (e && e.access && e.refresh) {
				console.log('set rest service token');
				console.log(e);
				this._token = e;
			} else {
				console.log('cleared rest service token');
				this._token = null;
			}
		});
	}

	public get(url: string): Observable<any> {
		// if(this.isNative()){
		//   return this.nativeGet(url);
		// }
		return this.httpClient.get(url).pipe(catchError((error) => of(new RestError(error, 'Problem making GET.'))));
	}

	async doNativeHttp(method, url: string, data?: any): Promise<any> {
		try {
			// const response = await this.http.get(url, params, headers);
			const response = await this.http.sendRequest(url, {
				method,
				data: data ? data : {},
				responseType: 'json',
				serializer: 'json',
			});

			console.log('tried NATIVE HTTP:');
			console.log(response.status);
			console.log(response);
			console.log(response.data);
			// console.log(JSON.parse(response.data)); // JSON data returned by server
			// console.log(response.headers);

			return response.data;
		} catch (error) {
			console.error(error.status);
			console.error(error.error); // Error message as string
			console.error(error.headers);

			return error;
		}
	}

	public nativeGet(url: string): Observable<any> {
		console.log('nativeGet()');
		return this.nativeHttp('GET', url);
	}

	public noAuthPost() {
		return '';
	}

	public post(url: string, data: any): Observable<any> {
		console.log('post()');
		console.log(url);
		console.log(data);

		// catch native requests in the interceptor after it fails
		return this.httpClient.post(url, data, this.options()).pipe(catchError((error) => of(new RestError(error, 'Problem making POST.'))));

		// if(this.isNative()){
		//   return this.nativeHttp('POST', url, data);
		// }else {
		//   return this.httpClient.post(url, data, this.postOptions())
		// }

		// return from(this.http.post(url, data, {
		//   headers: {
		//       'Content-Type': 'application/json',
		//       'Accept': 'application/json; version='+environment.api_version
		//   }
		// }).catch((err)=>{
		//   console.log('an error with native http');
		//   console.dir(err);
		//   let dataResponse = JSON.parse(err);
		//   console.log('dataResponse');
		//   console.log(dataResponse);
		//
		//   if(err){
		//     for (const key in err) {
		//       console.log(key+':');
		//       console.log(err);
		//     }
		//   }
		//   // alert(err);
		//   return err;
		// }).then((response)=>{
		//   console.log('response status:');
		//   console.log(response.status);
		//   try {
		//     response = JSON.parse(response.data);
		//     // prints test
		//     console.log(response);
		//     console.log(response.message);
		//   } catch(e) {
		//     console.error('JSON parsing error');
		//   }
		// }));
	}

	public patch(url: string, data: any): Observable<any> {
		console.log('patch()');
		console.log(url);
		console.log(data);

		// catch native requests in the interceptor after it fails
		return this.httpClient.patch(url, data, this.options()).pipe(catchError((error) => of(new RestError(error, 'Problem making PATCH.'))));
	}

	public delete(url: string): Observable<any> {
		return this.httpClient.delete(url, this.options()).pipe(catchError((error) => of(new RestError(error, 'Problem making DELETE.'))));
	}

	private _nativeHeaders() {
		if (this._token) {
			return {
				'Content-Type': 'application/json',
				Accept: 'application/json; version=' + environment.api_version,
			};
		}
		return {};
	}

	private nativeHttp(method, url: string, data?: any): Observable<any> {
		console.log('nativeHttp() ', method);
		console.log(url);

		return from(
			this.doNativeHttp(method, url, data)
			// this.http.sendRequest(url,
			//   {
			//     method: method,
			//     data: data ? data : {},
			//     headers: {
			//         'Content-Type': 'application/json',
			//         'Accept': 'application/json; version='+environment.api_version
			//     },
			//     timeout: 5000,
			//     responseType: 'json',
			//     serializer: 'json'
			//   }
			// )
			// .then((data)=> {
			//   console.log('finished nativeHttp request:');
			//   console.log(data);
			//   if(data['data']){
			//     console.log('data data exists');
			//     console.log(data.data);
			//   }
			//   return data;
			// })
			// .catch((err)=> {
			//   console.log('some native http error occured');
			//   if(err){
			//     console.log(err);
			//   }
			//   return err;
			// })
		);
		// .pipe(map((e)=>e.data))
	}

	private isNative(): boolean {
		if (this.platform.is('capacitor')) {
			return true;
		}
		return false;
	}

	private options() {
		return {
			headers: this.baseHeaders(),
			reportProgress: true,
		};
	}

	private baseHeaders() {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json; version=' + environment.api_version,
		});
	}

	// public authHeader() {
	//   return new HttpHeaders({
	//       'Content-Type': 'application/json',
	//       'Accept': 'application/json; version='+environment.api_version,
	//       'Authorization': 'JWT ' + this._stateAccessToken
	//   })
	// }
}
