/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, throwError } from 'rxjs';
import { RestService } from '../rest/rest.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { tap, catchError, switchMap, map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromApp from '../../app.reducer';
import * as AuthActions from './store/auth.actions';
import * as UserActions from '../user/store/user.actions';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { Token, TokenMaker, UserLogin } from './auth.types';
import { SocketService } from '../socket/socket.service';
import { UtilService } from '../util/util.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private tokenRenewalUrl = environment.base_api_url + '/auth/jwt/refresh/';
	private reAuthUserUrl = environment.base_api_url + '/auth/jwt/create/';
	private storeSubscription: Subscription;
	private _token: Token;
	private _tokenStorageSubscription: Subscription;
	private _renewAuthFromSocketSubscription: Subscription;

	constructor(
		private restService: RestService,
		private store: Store<fromApp.AppState>,
		private router: Router,
		private storageService: StorageService,
		private socketService: SocketService,
		private util: UtilService
	) {
		this.init();
	}

	public get token() {
		return this._token;
	}

	public set token(token: Token | undefined) {
		this.store.dispatch(new AuthActions.ValidToken(token));
		this._token = token;
	}

	public generateAuthToken(data: UserLogin): Observable<any> {
		console.log('generateAuthToken data:');
		console.log(data);
		return this.restService.post(environment.base_api_url + '/auth/jwt/create/', data).pipe(
			catchError((err) => {
				console.log('an error happened');
				console.log(err);
				this.store.dispatch(new AuthActions.LoginFail(err));
				return err;
			}),
			map((e: Token | any) => this.handleTokenSuccess(TokenMaker.create(e)))
		);
	}

	// public renewAuthToken(refresh: string): Observable<any> {
	// 	console.log('renewAuthToken()');
	// 	console.log(refresh);
	// 	return this.restService.post(
	// 		environment.base_api_url + '/auth/jwt/refresh/', {
	// 		refresh
	// 		}
	// 	)
	// 	.pipe(
	// 		map((e: Token) => this.handleTokenSuccess(e)),
	// 		catchError((error: any) => this.handleTokenError(error))
	// 	);
	// }

	// public renewToken_not_working(storedRefresh?: string): Observable<any> {
	// 	if(!this.token || !this.token.refresh && !storedRefresh) {
	// 		return throwError('No refresh token available');
	// 	}
	// 	const fetchPromise = fetch(this.tokenRenewalUrl, {
	// 		method: 'POST',
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 			accept: 'application/json; version='+environment.api_version
	// 		},
	// 		body: JSON.stringify({ refresh: storedRefresh ? storedRefresh : this.token.refresh })
	// 	});
	// 	const observableFromFetch = from(fetchPromise).pipe(
	// 		tap((response) => {

	// 			console.log('Response from fetch:');
	// 			console.log(response);

	// 			response.json().then(body => {
	// 				console.log('Response body:');
	// 				console.log(body);
	// 				return body;
	// 			});

	// 		}),
	// 		map((e)=>this.handleTokenSuccess(e)),
	// 		catchError((error: any) => this.handleTokenError(error))
	// 	);
	// 	return observableFromFetch;
	// }

	public renewToken(storedRefresh?: string): Observable<any> {
		console.log('renewToken()');
		if (!this.token && !storedRefresh) {
			return throwError('No refresh token available');
		}

		// console.log('this.token.refresh', this.token.refresh);
		// console.log('storedRefresh:', storedRefresh);

		const observableFromFetch = from(
			fetch(this.tokenRenewalUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					accept: 'application/json; version=' + environment.api_version,
				},
				body: JSON.stringify({
					refresh: storedRefresh ? storedRefresh : this.token.refresh,
				}),
			})
		).pipe(
			switchMap((response) => response.json()),
			tap((body) => {
				console.log('Response body:');
				console.log(body);
			}),
			map((e) => this.handleTokenSuccess(TokenMaker.create(e))),
			catchError((error: any) => this.handleTokenError(error))
		);

		return observableFromFetch;
	}

	public reAuthUser(login): Observable<any> {
		if (!login) {
			return throwError('No login credentials available');
		}
		const fetchPromise = fetch(this.reAuthUserUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json; version=' + environment.api_version,
			},
			body: JSON.stringify(login),
		});
		console.log('reAuthUser with fetch()');
		const observableFromFetch = from(fetchPromise).pipe(
			switchMap(async (response) => {
				const body = await response.json();
				console.log('reAuthUser Response body:');
				console.log(body);
				return body;
			}),
			catchError(() => throwError('Failed to authenticate'))
		);
		return observableFromFetch;
	}

	public logout() {
		this.store.dispatch(new AuthActions.Logout());
	}

	private init() {
		this.startAuthSubscription();
		this.checkStorageForToken();
	}

	private startAuthSubscription() {
		this.storeSubscription = this.store.select('auth').subscribe((authState) => {
			console.log('AuthService detected token arrival');
			console.log(authState);
			if (this._token == null && authState.access && authState.refresh) {
				console.log('user logged in! Should get user & open socket');
				this._token = TokenMaker.create(authState);
				this.storageService.setToken('tokens', TokenMaker.create(authState));
				this.socketService.init();
				this.store.dispatch(new UserActions.GetUser());
				this.router.navigate(['/tabs']);
			} else if (this._token != null && !authState.access && !authState.refresh) {
				this.storageService.clear('tokens');
				this.socketService.stopSocket('onLogout');
				this._token = null;
				this.router.navigate(['/tabs']);
			}
		});

		/* Listen to token renewal requests from the Socket */
		/* Process the renewal & update ngrx auth store */
		this._renewAuthFromSocketSubscription = this.socketService.socketAuthSubject.subscribe(() => {
			console.log('chat socket requesting a renewal flow....');
			this.renewToken()
				.pipe(
					catchError((err: any): Observable<any> => {
						console.log('problem renewing token from chat!');
						console.log(err);
						return err;
					})
				)
				.subscribe((response) => {
					console.log('renewToken response after socket requested it');
					console.log(response);

					/* need to refresh tab2 chat threads */
				});
		});
	}

	private checkStorageForToken() {
		this._tokenStorageSubscription = this.storageService.storedTokenSubject.subscribe((data) => {
			console.log('storage service found this:');
			console.log(data);

			if (data instanceof Token) {
				const t = data.access;
				const r = data.refresh;
				if (this.util.tokenExpired(t)) {
					console.log('token is expired!!!!');
					this.renewToken(r).subscribe((e) => {
						console.log('completed checkStorageForToken renewAuthToken');
					});
				} else {
					console.log('token is valid!!!');
					this.handleTokenSuccess(data);
				}
			}
		});
	}

	private handleTokenError(error: any): Observable<never> {
		console.error('Error renewing auth token:', error);
		return throwError('An error occurred while renewing the auth token.');
	}

	private handleTokenSuccess(e: Token): Token {
		console.log('handleTokenSuccess()');
		console.log(e);
		if (e && e.access) {
			this.token = e as Token; /* this setter dispatches ValidToken() */
			console.log('access', e.access);
			console.log('refresh', e.refresh);
		} else {
			this.token = undefined;
		}
		return e as Token;
	}
}
