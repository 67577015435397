export class Token {
	constructor(
		public access: string,
		public refresh: string
	) {}
}

export class TokenMaker {
	static create(e: Token) {
		return new Token(e.access, e.refresh);
	}
}

export interface UserLogin {
	email: string;
	password: string;
}

export interface UserRegistration {
	first: string;
	last: string;
	email: string;
	password_create: string;
	password_confirm: string;
	marketing: string;
}
