import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, tap, switchMap, mergeMap, concatMap, exhaustMap, map, first } from 'rxjs/operators';
import { Observable, throwError, of, Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Token } from '../auth/auth.types';
import { environment } from '../../../environments/environment';
import { ToastService } from '../toast/toast.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../app.reducer';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	storeSubscription: Subscription;
	lastToken = '';

	constructor(
		private authService: AuthService,
		private toastService: ToastService,
		private store: Store<fromApp.AppState>
	) {
		console.log('token interceptor constructor');
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		console.log('token-interceptor is listening...');

		return this.store.select('auth').pipe(
			first(),
			mergeMap((token: Token | undefined) => {
				if (token && token.access) {
					if (token.access !== this.lastToken) {
						console.log('%cInterceptor USING NEW TOKEN', 'font-size: 1.6rem; color:green; background:black;');
					}

					console.log('adding token to request...');
					console.log(token.access);

					request = request.clone({
						headers: request.headers
							.set('Content-Type', 'application/json')
							.set('Accept', 'application/json; version=' + environment.api_version)
							.set('Authorization', 'JWT ' + token.access),
					});

					this.lastToken = token.access;
				} else {
					console.log('%cInterceptor NO TOKEN EMITTED', 'font-size: 1.2rem; color:yellow; background:black;');
					this.lastToken = '';
				}

				if (token.access !== this.lastToken) {
					console.log('%cInterceptor USING NEW TOKEN', 'font-size: 1.6rem; color:green; background:black;');
				}

				return next.handle(request).pipe(
					catchError((err: any): Observable<any> => {
						if (err instanceof HttpErrorResponse) {
							console.log('<<<<<<<<<<<<<<<<<<<<<<<->>>>>>>>>>>>>>>>>>>>>>>');
							console.log('<<<<<<<<<<<<<<< NETWORK ERROR >>>>>>>>>>>>>>>>>');
							console.log('<<<<<<<<<<<<<<<<<<<<<<<->>>>>>>>>>>>>>>>>>>>>>>');
							switch ((err as HttpErrorResponse).status) {
								case 400:
									return this.handle400Error(err);
								case 401:
									return this.handle401Error(err, request, next);
								default:
									return throwError(err);
							}
						}
					})
				);
			})
		);
	}

	handle400Error(error: HttpErrorResponse): Observable<any> {
		console.log('handle400Error()');
		console.log(error);
		return throwError(error);
	}

	handle401Error(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any | HttpEvent<any>> {
		// returned a 401 after including the auth token
		// check if the reason is due to bad auth token
		// if so, attempt renewal of auth.refresh
		// on failure, logout
		console.log('handle401Error()');
		console.log(error);
		console.log(error.error);

		// is the 401 due to auth token failure? (could also happen with resource missing?)
		// need to wrap following block with above logic.
		console.log('interceptor will try renewToken');
		if (!this.authService.token || !this.authService.token.refresh) {
			console.log('no refresh token is set!');
			// initial login hits this currently,
			// but that could be a direct call with unauth
			console.log(this.authService.token);
			return of(error);
		}

		return this.authService.renewToken().pipe(
			switchMap((data) => {
				console.log('renewToken data');
				console.log('NEW TOKEN ARRIVAL, applying to request....');
				console.log(data.access);

				if (data && data.access && data.refresh) {
					console.log('FIXED THE TOKEN!');
					console.log('returning cloned request with freshly returned token...');
					// request = request.clone({ headers: request.headers.set('Authorization', 'JWT ' + c.access ) });
					return next.handle(
						request.clone({
							setHeaders: { authorization: 'JWT ' + data.access },
						})
					);
				} else {
					console.log('we did NOT receive the token back');
					this.handle401Outcomes(error);
					return throwError({
						detail: 'Server connection failure',
						code: 'unknown_connection_failure',
					});
				}
			})
		);
	}

	handle401Outcomes(error: HttpErrorResponse) {
		let failCode = null;
		let failMessage = '';
		let failUrl = '';
		let errObj;
		if (error && error.error) {
			errObj = error.error;
			if (errObj.code) {
				failCode = errObj.code;
			}
			if (errObj.detail) {
				failMessage = errObj.detail;
			}
			if (error.url) {
				failUrl = error.url;
			}
		}

		if (failCode === 'token_not_valid') {
			console.log('failUrl:');
			console.log(failUrl);
			const pos = failUrl.indexOf('auth/jwt/refresh/');
			if (pos > -1) {
				console.log('this failure was the result of a tried refresh token attempt.');

				// should not do another refresh try.
				// clear the failed token from storage
				this.authService.logout();

				this.toastService.show('Failed to refresh session. Please login again.');

				return of(error);
			}
		}
	}
}
