import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
	providedIn: 'root',
})
export class AlertService {
	constructor(public alertController: AlertController) {}

	public async show(title: any, message?: any, buttons?: any, inputs?: any, backdropDismiss = true) {
		let buttonGroup = buttons;
		if (!buttons) {
			buttonGroup = [
				{
					text: 'Ok',
					handler: () => {
						console.log('Ok clicked!');
					},
				},
			];
		}
		let msg = null;
		if (message) {
			msg = message;
		}
		let inputGroup = null;
		if (inputs) {
			inputGroup = inputs;
		}
		const alert = await this.alertController.create({
			header: title,
			message: msg,
			inputs: inputGroup,
			buttons: buttonGroup,
			backdropDismiss,
		});

		alert.present();
	}

	public async presentFailedUpdateAlert(err?: any): Promise<void> {
		console.log('presentFailedAuthenticationAlert()');
		console.log(err);

		let message = '';
		if (err && typeof err.error !== 'undefined' && typeof err.error.error !== 'undefined' && typeof err.error.error.errors !== 'undefined') {
			if (typeof err.error.error.errors[0] !== 'undefined') {
				message = err.error.error.errors[0];
			}
		}

		if (!message) {
			if (
				typeof err.error !== 'undefined' &&
				typeof err.error.error !== 'undefined' &&
				typeof err.error.error.number !== 'undefined' &&
				typeof err.error.error.number[0] !== 'undefined'
			) {
				message = err.error.error.number[0];
			}
		}

		if (!message) {
			if (typeof err.error !== 'undefined' && typeof err.error.errors !== 'undefined' && typeof err.error.errors[0] !== 'undefined') {
				message = err.error.errors[0];
			}
		}

		if (!message && err?.error) {
			try {
				// If err.error is a string and starts with '{', attempt to parse it as JSON
				if (typeof err.error === 'string' && err.error.trim().startsWith('{')) {
					const errObj = JSON.parse(err.error);
					console.log('Parsed Error Object:', errObj);

					// Safely extract the 'detail' property
					message = errObj?.detail ?? message;
				} else if (typeof err.error === 'string') {
					// If err.error is a plain string, use it as the error message
					message = err.error;
				}
			} catch (parseError) {
				console.error('Error parsing JSON error response:', parseError);
			}
		}

		if (!message) {
			message = 'Please try again.';
		}

		const alert = await this.alertController.create({
			header: 'An Error Occurred',
			buttons: ['OK'],
			message,
		});
		await alert.present();
	}
}
