/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
// import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
// import { environment } from '../../../environments/environment';
import { tap, catchError, switchMap, map, withLatestFrom } from 'rxjs/operators';
// import { of } from 'rxjs/observable/of';
// import { User } from '../../../providers/user/user.provider';
import * as AuthActions from './auth.actions';
import * as UserActions from '../../user/store/user.actions';
// import * as FavActions from '../../../providers/listing/favStore/fav.actions';
// import * as SaleActions from '../../../providers/listing/saleStore/sale.actions';
// import * as SoldActions from '../../../providers/listing/soldStore/sold.actions';
// import * as InboxActions from '../../../providers/inbox/store/inbox.actions';
// import * as IapActions from '../../../providers/iap/store/iap.actions';
// import { RestProvider, Token } from '../../../providers/rest/rest.provider';
// import { IonicStorageModule } from '@ionic/storage-angular';
// import { NativeStorage } from '@ionic-native/native-storage';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../app.reducer';
// import { ListingProvider } from '../../../providers/listing/listing.provider';
import { Observable, of } from 'rxjs';
// import { ServiceProvider } from '../../../providers/service/service';
import { Token } from '../../auth/auth.types';
import { AuthService } from '../../auth/auth.service';
import { NavController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { LoaderService } from '../../loader/loader.service';
import { StorageService } from '../../storage/storage.service';
import { Router } from '@angular/router';
import { ChatService } from '../../chat/chat.service-bak';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AuthEffects {
	constructor(
		public actions$: Actions,
		public store: Store<fromApp.AppState>,
		public authService: AuthService,
		public platform: Platform,
		public storage: Storage,
		private loaderService: LoaderService,
		private storageService: StorageService,
		private router: Router,
		private navCtrl: NavController,
		private chatService: ChatService
		// private http:HttpClient,
		// public rest: RestProvider,
		// private storage: IonicStorageModule,
		// private nativeStorage: NativeStorage,
		// private app: App,
		// private listingProvider: ListingProvider,
		// private service: ServiceProvider
	) {}

	authLogin$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.LOGIN_START),
			switchMap((action: AuthActions.LoginStart) =>
				this.authService.generateAuthToken(action.payload).pipe(
					switchMap((data: Token) => {
						this.loaderService.hideLoader();
						return [new UserActions.GetUser()];
					}),
					catchError((error: HttpErrorResponse) => {
						this.loaderService.hideLoader();
						const errorMessage = error.status === 401 ? error.error?.error?.detail || 'Authentication failed' : 'An unexpected error occurred';
						return of(new AuthActions.LoginFail(errorMessage));
					})
				)
			)
		)
	);

	authLogout$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.LOGOUT),
			switchMap((action: AuthActions.Logout) => {
				this.storageService.clearAuthStorage();
				// this.router.navigate([]);
				this.navCtrl.navigateRoot(['login', 'signin'], { replaceUrl: true });
				return [];
			})
		)
	);
	// @Effect()
	// authTokenRefresh = this.actions$.pipe(
	//   ofType(AuthActions.TOKEN_REFRESH),
	//   switchMap((authData: AuthActions.TokenRefresh)=> {
	//     console.log('AuthActions.TokenRefresh authData:');
	//     let refresh = null;
	//     if(authData && authData['payload']){
	//       refresh = authData['payload'];
	//     }
	//     return this.rest.renewToken(refresh)
	//     .pipe(withLatestFrom(this.store.select('user')))
	//   }),
	//   switchMap((inDataArray,index):any => {
	//     console.log('renewToken switchMap data');
	//     // console.log(response);
	//     // let data = JSON.parse(response['_body']);
	//     console.log(inDataArray);
	//     console.log('index', index);
	//     let state = null;
	//     let data:any = null;
	//     if(inDataArray && inDataArray.hasOwnProperty('length') && inDataArray.length > 0){
	//       data = inDataArray[0];
	//       state = inDataArray[1];
	//     }

	//     console.log('renewToken response body:');
	//     console.log(data);

	//     console.log('authTokenRefresh output state:');
	//     console.log(state);

	//     if(data && data.hasOwnProperty('error')){
	//       console.log('renewToken error! clear auth! will send user to logout sequence');
	//       return of(new AuthActions.Logout())
	//     }else{
	//       // return Observable.of(new AuthActions.ValidToken(data))
	//       console.log('will return actions');

	//       //if not logged in previously
	//       if(state && state.hasOwnProperty('user') && !state['user']){
	//         console.log('dd: returning GetUser & ValidToken actions');
	//         return [
	//           new AuthActions.ValidToken(data),
	//           new UserActions.GetUser()
	//         ]
	//       }else{
	//         console.log('dd: returning ValidToken action, user was previously logged in');
	//         return of(new AuthActions.ValidToken(data))
	//       }
	//     }
	//   })
	// );

	//   @Effect({ dispatch: false })
	//   validToken = this.actions$.pipe(
	//     ofType(AuthActions.VALID_TOKEN),
	//     tap( (data)=> {
	//       console.log('AuthActions.VALID_TOKEN effects');
	// 	  this.storage.set('tokens', data['payload'])
	//       console.log('updated stored tokens');
	//       console.log(data['payload']);
	//     })
	//   );

	validToken = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AuthActions.VALID_TOKEN),
				tap((action: AuthActions.ValidToken) => {
					console.log('AuthActions.VALID_TOKEN effects');
					this.storage.set('tokens', action.payload);
					console.log('Updated stored tokens');
					console.log(action.payload);
				})
			),
		{ dispatch: false }
	);
}
