import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Drivers, Storage } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicSelectableModule } from 'ionic-selectable';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './providers/interceptor/token-interceptor';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './providers/auth/store/auth.effects';
import { UserEffects } from './providers/user/store/user.effects';
import * as fromApp from './app.reducer';
import { File } from '@ionic-native/file/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { NativeHttpInterceptor } from './providers/interceptor/native-http-interceptor';
import { SocketService } from './providers/socket/socket.service';
import { SwiperModule } from 'swiper/angular';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { DirectivesModule } from './directives/directives.module';
// import { TourIonPopoverModule } from 'ngx-ui-tour-ionic';

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		IonicStorageModule.forRoot({
			name: '__ponyplace',
			driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
		}),
		FormsModule,
		ReactiveFormsModule,
		IonicSelectableModule,
		HttpClientModule,
		StoreModule.forRoot(fromApp.appReducer),
		EffectsModule.forRoot([
			AuthEffects,
			UserEffects,
			// FavEffects,
			// SaleEffects,
			// SoldEffects,
			// InboxEffects,
			// ChatEffects,
			// IapEffects
		]),
		// JwtModule.forRoot({
		//   jwtOptionsProvider: {
		//     provide: JWT_OPTIONS,
		//     // useFactory: jwtOptionsFactory,
		//     deps: [Storage]
		//   }
		// })
		SuperTabsModule.forRoot(),
		SwiperModule,
		LeafletModule,
		DirectivesModule,
		// TourIonPopoverModule,
		// RouterModule
	],
	providers: [
		File,
		HTTP,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: NativeHttpInterceptor, multi: true },
		SocketService,
		OneSignal,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
