/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RestService } from '../rest/rest.service';
import { PageResponse, RestError } from '../rest/rest.types';
import { Store } from '@ngrx/store';
import * as fromApp from '../../app.reducer';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { Token } from '../auth/auth.types';
import { UtilService } from '../util/util.service';
import { map } from 'rxjs/operators';
import { SocketService } from '../socket/socket.service';
import { User } from '../user/user.service';

@Injectable({
	providedIn: 'root',
})
export class ChatService {
	public items: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	public typingIndicatorDelay = 1500;
	public messages: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	private threadsServerResponse: PageResponse;
	private _messages: any[];
	private unreadCountSubject = new BehaviorSubject<number>(0);
	public unreadCount$ = this.unreadCountSubject.asObservable();
	private serverResponse: PageResponse;
	private _items = [];
	private _totalItems = [];
	private _user: User;

	constructor(
		private restService: RestService,
		private store: Store<fromApp.AppState>,
		private utilService: UtilService,
		private socketService: SocketService
	) {
		console.log('chat service constructor');
		this.store.select('user')
			.pipe(map((e) => e.user))
			.subscribe((user) => {
				if (user) {
					this._user = user;
					console.log('User subscription: User loaded', user);
				}
			});

		this.socketService.socketSubject.subscribe((message) => {
			if (message.type === 'chat.message' && !this.isMessageFromCurrentUser(message)) {
				this.incrementUnreadCount();
			}
		});
		this.socketService.socketReadSubject.subscribe((readEvent) => {
			if (this.isCurrentUserMessage(readEvent)) {
				this.decrementUnreadCount();
			}
		});
	}

	private calculateUnreadCount(threads: any[]): number {
		return threads.reduce((total, thread) => total + (thread.unread_count || 0), 0);
	}

	public updateUnreadCount(threads: any[]): void {
		const unreadCount = this.calculateUnreadCount(threads);
		this.unreadCountSubject.next(unreadCount);
	}

	private incrementUnreadCount(): void {
		const currentCount = this.unreadCountSubject.value;
		this.unreadCountSubject.next(currentCount + 1);
	}

	private decrementUnreadCount(): void {
		const currentCount = this.unreadCountSubject.value;
		if (currentCount > 0) {
			this.unreadCountSubject.next(currentCount - 1);
		}
	}

	private isMessageFromCurrentUser(message: any): boolean {
		if (!this._user) return false;
		return message.sender === this._user.pk;
	}

	private isCurrentUserMessage(readEvent: any): boolean {
		if (!this._user) return false;
		return readEvent.sender === this._user.pk;
	}

	public get totalItems() {
		return this._totalItems;
	}

	public getAllThreads(): Observable<any> {
		return this.restService.get(`${environment.base_api_url}/chat/?page_size=0`).pipe(
			map((response) => {
				if (response && response.results.length) {
					this._totalItems = [...response.results];
					this.updateUnreadCount(this._totalItems)
				}
				return response.results;
			})
		);
	}

	public clearThreads(): void {
		this._totalItems = [];
		this.resetMessages();
	}

	public getThreads(next?: string): Observable<any> {
		return this.restService.get(next || `${environment.base_api_url}/chat/`).pipe(
			map((response) => {
				if (response && response.results.length) {
					const merged = [...this._totalItems, ...response.results];
					this._totalItems = merged.filter((item, index, self) => index === self.findIndex((t) => t.pk === item.pk));
					this.updateUnreadCount(this._totalItems)

				}
				return response;
			})
		);
	}

	public getMessages(threadPk: string, next?: string): Observable<any> {
		return this.restService.get(next ? next : environment.base_api_url + '/chat/' + threadPk + '/messages/');
	}

	public resetMessages(): void {
		this._messages = [];
		this.messages.next(this._messages);
	}

	public getUserThreadById(threadPk: string): Observable<any> {
		return this.restService.get(environment.base_api_url + '/chat/' + threadPk);
	}

	public findUserThreadPk(userPk: string): string | null {
		const foundThread = this._totalItems.find((thread) =>
			thread.other_participants.some((participant) => participant.pk === userPk)
		);
		return foundThread ? foundThread.pk : null;
	}
}

// // /* eslint-disable no-underscore-dangle */
// // import { Injectable, OnDestroy } from '@angular/core';
// // import { environment } from '../../../environments/environment';
// // import { RestService } from '../rest/rest.service';
// // import { PageResponse, RestError } from '../rest/rest.types';
// // import { Store } from '@ngrx/store';
// // import * as fromApp from '../../app.reducer';
// // import { Subscription, BehaviorSubject, Observable } from 'rxjs';
// // import { Token } from '../auth/auth.types';
// // import { UtilService } from '../util/util.service';
// // import { map } from 'rxjs/operators';
// // import { pipe } from 'rxjs';
// // import { SocketService } from '../socket/socket.service';

// // @Injectable({
// // 	providedIn: 'root',
// // })
// // export class ChatService implements OnDestroy {
// // 	public items: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
// // 	public messages: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
// // 	public typingIndicatorDelay = 1500;

// // 	private unreadCountSubject = new BehaviorSubject<number>(0);
// // 	public unreadCount$ = this.unreadCountSubject.asObservable();

// // 	private threadsServerResponse: PageResponse;
// // 	private _messages: any[];
// // 	private _unreadMessages = 0;
// // 	private serverResponse: PageResponse;
// // 	private _items = [];
// // 	private _totalItems = [];
// // 	private currentUserId: string | null = null;
// // 	private userSubscription: Subscription;
// // 	private socketSubscriptions: Subscription[] = [];

// // 	constructor(
// // 		private restService: RestService,
// // 		private store: Store<fromApp.AppState>,
// // 		private utilService: UtilService,
// // 		private socketService: SocketService
// // 	) {
// // 		console.log('chat service constructor');
// // 		this.initializeSubscriptions();
// // 	}

// // 	private initializeSubscriptions() {
// // 		// Subscribe to user state
// // 		this.userSubscription = this.store.select('user').subscribe((userState) => {
// // 			if (userState.user) {
// // 				this.currentUserId = userState.user.pk;
// // 			} else {
// // 				this.currentUserId = null;
// // 			}
// // 		});

// // 		// Socket message subscription
// // 		this.socketSubscriptions.push(
// // 			this.socketService.socketSubject.subscribe((message) => {
// // 				if (message.type === 'chat.message' && !this.isMessageFromCurrentUser(message)) {
// // 					this.incrementUnreadCount();
// // 				}
// // 			})
// // 		);

// // 		// Socket read receipt subscription
// // 		this.socketSubscriptions.push(
// // 			this.socketService.socketReadSubject.subscribe((readEvent) => {
// // 				if (this.isCurrentUserMessage(readEvent)) {
// // 					this.decrementUnreadCount();
// // 				}
// // 			})
// // 		);
// // 	}

// // 	ngOnDestroy() {
// // 		if (this.userSubscription) {
// // 			this.userSubscription.unsubscribe();
// // 		}
// // 		this.socketSubscriptions.forEach((sub) => sub.unsubscribe());
// // 	}

// // 	findUserThreadPk(userPk: string): string | null {
// // 		const foundThread = this.totalItems.find((thread) => thread.other_participants.some((participant) => participant.pk === userPk));
// // 		return foundThread ? foundThread.pk : null;
// // 	}

// // 	hasThisThread(ownerPk: string): any | boolean {
// // 		console.log('ownerPk', ownerPk);
// // 		return false;
// // 	}

// // 	get totalItems() {
// // 		return this._totalItems;
// // 	}

// // 	get unreadMessages(): number {
// // 		return this._unreadMessages;
// // 	}

// // 	set unreadMessages(i: number) {
// // 		this._unreadMessages = i;
// // 	}

// // 	getAllThreads(): Observable<any> {
// // 		return this.restService.get(`${environment.base_api_url}/chat/?page_size=0`).pipe(
// // 			map((response) => {
// // 				if (response && response.results.length) {
// // 					this._totalItems = [...response.results];
// // 					const unreadCount = this.calculateUnreadCount(response.results);
// // 					this.unreadCountSubject.next(unreadCount);
// // 				}
// // 				return response.results;
// // 			})
// // 		);
// // 	}

// // 	clearThreads() {
// // 		this._totalItems = [];
// // 		this.resetMessages();
// // 		this.unreadCountSubject.next(0);
// // 	}

// // 	getThreads(next?: string): Observable<any> {
// // 		return this.restService.get(next || `${environment.base_api_url}/chat/`).pipe(
// // 			map((response) => {
// // 				if (response && response.results.length) {
// // 					const merged = [...this._totalItems, ...response.results];
// // 					this._totalItems = merged.filter((item, index, self) => index === self.findIndex((t) => t.pk === item.pk));
// // 					const unreadCount = this.calculateUnreadCount(this._totalItems);
// // 					this.unreadCountSubject.next(unreadCount);
// // 				}
// // 				return response;
// // 			})
// // 		);
// // 	}

// // 	getMessages(threadPk: string, next?: string): Observable<any> {
// // 		return this.restService.get(next ? next : environment.base_api_url + '/chat/' + threadPk + '/messages/');
// // 	}

// // 	getUserThreadMessagesById(threadPk: string, reset = false): Observable<any> {
// // 		console.log('getUserThreadMessagesById()');

// // 		let url = environment.base_api_url + '/chat/' + threadPk + '/messages/';
// // 		if (!reset && this.threadsServerResponse && this.threadsServerResponse.next) {
// // 			url = this.threadsServerResponse.next;
// // 		}
// // 		if (reset === true) {
// // 			this._messages = [];
// // 		}
// // 		return this.restService.get(url).pipe(
// // 			map((data) => {
// // 				console.log('messages:', data);
// // 				if (!(data instanceof RestError)) {
// // 					this.threadsServerResponse = data;
// // 					if (data.results) {
// // 						if (this._messages && this._messages.length > 0) {
// // 							this._messages = [...this._messages, ...data.results];
// // 						} else {
// // 							this._messages = data.results;
// // 						}
// // 					}
// // 					this.messages.next(this._messages);
// // 				}
// // 				return data;
// // 			})
// // 		);
// // 	}

// // 	public resetMessages(): void {
// // 		this._messages = [];
// // 		this.messages.next(this._messages);
// // 	}

// // 	public getUserThreadById(threadPk: string): Observable<any> {
// // 		return this.restService.get(environment.base_api_url + '/chat/' + threadPk);
// // 	}

// // 	private calculateUnreadCount(threads: any[]): number {
// // 		return threads.reduce((total, thread) => total + (thread.unread_count || 0), 0);
// // 	}

// // 	private incrementUnreadCount(): void {
// // 		const currentCount = this.unreadCountSubject.value;
// // 		this.unreadCountSubject.next(currentCount + 1);
// // 	}

// // 	private decrementUnreadCount(): void {
// // 		const currentCount = this.unreadCountSubject.value;
// // 		if (currentCount > 0) {
// // 			this.unreadCountSubject.next(currentCount - 1);
// // 		}
// // 	}

// // 	private isMessageFromCurrentUser(message: any): boolean {
// // 		return message.sender === this.currentUserId;
// // 	}

// // 	private isCurrentUserMessage(readEvent: any): boolean {
// // 		return readEvent.sender === this.currentUserId;
// // 	}

// // 	public markThreadMessagesAsRead(threadId: string): void {
// // 		const thread = this._totalItems.find((t) => t.pk === threadId);
// // 		if (thread && thread.unread_count > 0) {
// // 			thread.unread_count = 0;
// // 			const unreadCount = this.calculateUnreadCount(this._totalItems);
// // 			this.unreadCountSubject.next(unreadCount);
// // 		}
// // 	}
// // }

// /* eslint-disable no-underscore-dangle */
// import { Injectable } from '@angular/core';
// import { environment } from '../../../environments/environment';
// import { RestService } from '../rest/rest.service';
// import { PageResponse, RestError } from '../rest/rest.types';
// import { Store } from '@ngrx/store';
// import * as fromApp from '../../app.reducer';
// import { Subscription, BehaviorSubject, Observable } from 'rxjs';
// import { Token } from '../auth/auth.types';
// import { UtilService } from '../util/util.service';
// import { map } from 'rxjs/operators';
// import { pipe } from 'rxjs';
// import { SocketService } from '../socket/socket.service';

// @Injectable({
// 	providedIn: 'root',
// })
// export class ChatService {
// 	public items: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
// 	// private _tokenSubscription:Subscription;
// 	// private _token: Token;
// 	public typingIndicatorDelay = 1500;
// 	public messages: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
// 	private threadsServerResponse: PageResponse;
// 	private _messages: any[];
// 	private unreadCountSubject = new BehaviorSubject<number>(0);
// 	public unreadCount$ = this.unreadCountSubject.asObservable();
// 	private serverResponse: PageResponse;
// 	private _items = [];

// 	private _totalItems = []; // runs on user login, and accumulates on getThreads()

// 	constructor(
// 		private restService: RestService,
// 		private store: Store<fromApp.AppState>,
// 		private utilService: UtilService,
// 		private socketService: SocketService
// 	) {
// 		// this._tokenSubscription = this.store.select('auth')
// 		// .subscribe((e:Token)=>{
// 		//   console.log('chat constructor e:');
// 		//   console.log(e);
// 		//   if(e['access']){
// 		//     console.log('set chat service token');
// 		//     console.log(e);
// 		//     this._token = e;

// 		//     this.getUserThreads()

// 		//   }else{
// 		//     console.log('cleared chat service token');
// 		//     this._token = null;
// 		//     this.items.next([]);
// 		//   }
// 		// })

// 		console.log('chat service constructor');
// 		this.socketService.socketSubject.subscribe((message) => {
// 			if (message.type === 'chat.message' && !this.isMessageFromCurrentUser(message)) {
// 				this.incrementUnreadCount();
// 			}
// 		});
// 		this.socketService.socketReadSubject.subscribe((readEvent) => {
// 			if (this.isCurrentUserMessage(readEvent)) {
// 				this.decrementUnreadCount();
// 			}
// 		});
// 	}

// 	private calculateUnreadCount(threads: any[]): number {
// 		return threads.reduce((total, thread) => total + (thread.unread_count || 0), 0);
// 	}

// 	private incrementUnreadCount(): void {
// 		const currentCount = this.unreadCountSubject.value;
// 		this.unreadCountSubject.next(currentCount + 1);
// 	}

// 	private decrementUnreadCount(): void {
// 		const currentCount = this.unreadCountSubject.value;
// 		if (currentCount > 0) {
// 			this.unreadCountSubject.next(currentCount - 1);
// 		}
// 	}

// 	// private isMessageFromCurrentUser(message: any): boolean {
// 	// 	// Implement based on your user authentication logic
// 	// 	const currentUserId = this.getCurrentUserId(); // You'll need to implement this
// 	// 	return message.sender === currentUserId;
// 	// }

// 	// private isCurrentUserMessage(readEvent: any): boolean {
// 	// 	const currentUserId = this.getCurrentUserId();
// 	// 	return readEvent.sender === currentUserId;
// 	// }

// 	//   getUserThreads(reset?: boolean): Promise<any> {
// 	//     console.log('getUserThreads()');
// 	//     return new Promise( (resolve,reject)=> {
// 	//       let url = environment.base_api_url + '/chat/';
// 	//       if( !reset && this.serverResponse && this.serverResponse.next ) {
// 	//         url = this.serverResponse.next;
// 	//       }
// 	//       if( reset === true ) {
// 	//         this._items = [];
// 	//       }
// 	//       this.restService.get( url )
// 	//       .subscribe((data)=> {
// 	//         console.log('finished loading user threads:');
// 	//         if(! (data instanceof RestError) ) {
// 	//           console.log(data);
// 	//           this.serverResponse = data;

// 	//           if( data.results ) {
// 	//             if( this._items && this._items.length > 0 ) {
// 	//               this._items = [ ...this._items, ...data.results ];
// 	//             } else {
// 	//               this._items = data.results;
// 	//             }
// 	//           }
// 	//           this.items.next(this._items);
// 	//           resolve(data);
// 	//         }
// 	//         reject();
// 	//       });
// 	//     });
// 	//   }

// 	findUserThreadPk(userPk: string): string | null {
// 		const foundThread = this.totalItems.find((thread) => thread.other_participants.some((participant) => participant.pk === userPk));
// 		return foundThread ? foundThread.pk : null;
// 	}

// 	hasThisThread(ownerPk: string): any | boolean {
// 		// use ownerPk to find threadPk
// 		console.log('ownerPk', ownerPk);
// 		// const thread = this._totalItems.filter((e)=>e.pk===threadPk);
// 		// if(thread && thread.length) {
// 		// 	return thread;
// 		// }
// 		return false;
// 	}

// 	get totalItems() {
// 		return this._totalItems;
// 	}

// 	/* calc this  */

// 	getAllThreads(): Observable<any> {
// 		return this.restService.get(`${environment.base_api_url}/chat/?page_size=0`).pipe(
// 			map((response) => {
// 				if (response && response.results.length) {
// 					this._totalItems = [...response.results];
// 				}
// 				return response.results;
// 			})
// 		);
// 	}

// 	clearThreads() {
// 		this._totalItems = [];
// 		this.resetMessages();
// 	}

// 	getThreads(next?: string): Observable<any> {
// 		return this.restService.get(next || `${environment.base_api_url}/chat/`).pipe(
// 			map((response) => {
// 				if (response && response.results.length) {
// 					const merged = [...this._totalItems, ...response.results];
// 					this._totalItems = merged.filter((item, index, self) => index === self.findIndex((t) => t.pk === item.pk));
// 				}
// 				return response;
// 			})
// 		);
// 	}

// 	getMessages(threadPk: string, next?: string): Observable<any> {
// 		return this.restService.get(next ? next : environment.base_api_url + '/chat/' + threadPk + '/messages/');
// 	}

// 	getUserThreadMessagesById(threadPk: string, reset = false): Observable<any> {
// 		console.log('getUserThreadMessagesById()');

// 		let url = environment.base_api_url + '/chat/' + threadPk + '/messages/';
// 		if (!reset && this.threadsServerResponse && this.threadsServerResponse.next) {
// 			url = this.threadsServerResponse.next;
// 		}
// 		if (reset === true) {
// 			this._messages = [];
// 		}
// 		return this.restService.get(url).pipe(
// 			map((data) => {
// 				console.log('messages:');
// 				console.log(data);
// 				if (!(data instanceof RestError)) {
// 					this.threadsServerResponse = data;
// 					if (data.results) {
// 						if (this._messages && this._messages.length > 0) {
// 							this._messages = [...this._messages, ...data.results];
// 						} else {
// 							this._messages = data.results;
// 						}
// 					}
// 					this.messages.next(this._messages);
// 				}
// 				return data;
// 			})
// 		);
// 	}

// 	public resetMessages(): void {
// 		this._messages = [];
// 		this.messages.next(this._messages);
// 	}

// 	public getUserThreadById(threadPk: string): Observable<any> {
// 		return this.restService.get(environment.base_api_url + '/chat/' + threadPk);
// 	}
// }
